<template>
  <div class="dash"></div>
</template>

<script>
export default {
  name: 'Dash',
};
</script>

<style lang="scss">
.dash {
  border-top: 1.5px solid #C1C1C1;
  width: 90%;
  position: fixed;
  left: 5%;
  z-index: 3;
}
</style>
